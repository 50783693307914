import { TransactionType } from "../typedefs";
import * as zlib from 'zlib';

type CookieObjectType = {
    "max-age"?: number,
    path?: string,
    samesite?: 'lax' | "strict",
}
type CnameType = "hs_auth_token" | "sbqh_auth_token" | "qardan_auth_token"

export function setCookie(c_name: CnameType, value: string, attrs?: CookieObjectType, secure?: boolean) {
    const attrsString = Object.entries(attrs || {})
        .map(v => `${v[0]} = ${v[1]};`)
        .join(' ');

    const cookieString = `${c_name} = ${value};` +
        attrsString +
        `${secure ? "secure" : ""}`

    // console.log(cookieString);
    document.cookie = cookieString;

}

export function getCookie(cName: CnameType) {
    const cookies = document.cookie.split('; ');
    // console.log(document.cookie)
    for (let cookie of cookies) {
        // console.log(cookie.split('='))
        if (cookie.split('=')[0] === cName) {
            return cookie.split('=')[1]
        }
    }

    return null;
}

export function deleteCookie(c_name: CnameType) {
    document.cookie = `${c_name}= ; max-age=-1 `
    // setCookie('hs_auth_token', "", { "max-age": -1 })
}


export const compare2Objects = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    for (let objKey of obj1Keys) {
        if (obj1[objKey] !== obj2[objKey]) {
            return false;
        }
    }

    return true;
};

export const hsTmNumberRegex = /^([hH][sS]|[tT][mM])[0-9]*$/;

export const qardanFileNumberRegex = /^([Ff][Bb]|[Ss][bB][Qq][Hh]-)([0-9]|[Pp][0-9])*$/;

export const TransactionDescription = {
    [TransactionType.So]: "SO",
    [TransactionType.Bf]: "B/F",
    [TransactionType.Cash]: "CASH",
    [TransactionType.Chq]: "CHQ",
    [TransactionType.PaidOl]: "PAID OL",
    [TransactionType.Dc]: "DC",
}

/**
 * 
 * @param object it must be a simple object not a complex one
 * @param key could be like => "personal" or "personal.applicant_photo"
 * @param value it could be any type
 */
export const setValueInObjectField = (object: object, key: string, value: any) => {
    // const setValueInObjectField = (object, key, value) => {
    let field;
    const keyList = key.split(".");
    let index = 0;
    for (let key of keyList) {
        if (keyList.length === 1) {
            object[key] = value;
            break
        }
        else if (field) {
            if (index + 1 === keyList.length) {
                field[key] = value; return
            }
            field = field?.[key];
        } else {
            if (index + 1 === keyList.length) {
                // console.log(field, key)
                field[key] = value; return
            }
            field = object?.[key];
        }
        if (!field) break;
        index++
    }
}


export const formatNum = (num = 0, floatDistance = 2) => {
    const absoluteNum = Number(num);
    if (isNaN(absoluteNum)) { return absoluteNum }

    const _num = absoluteNum.toLocaleString();
    const dotSplitNum = _num.split(".");
    if (!dotSplitNum[1]) {
        dotSplitNum[1] = "00"
    } else if (dotSplitNum[1].length === 1) {
        dotSplitNum[1] = dotSplitNum[1] + "0"
    }

    dotSplitNum[1] = dotSplitNum[1].slice(0, floatDistance)

    return dotSplitNum.join('.');
}

export const stringZipper = (str: string, method: "zip" | "unzip") => {
    if (method === "zip") { return String.raw`${zlib.deflateSync(str).toString('hex')}` }
    else { return String.raw`${zlib.inflateSync(Buffer.from(str, 'hex')).toString()}` }
}

export const getFileExt = (fileName: string) => {
    if (typeof fileName !== 'string') {
        throw new Error('Invalid input: fileName must be a string');
    }

    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        // No extension found or name starts with a dot (e.g., ".hiddenfile")
        return '';
    }

    return "." + fileName.slice(lastDotIndex + 1);
}

export function getQueryParam(name: string) {
    return new URLSearchParams(window.location.search).get(name) || undefined
}
